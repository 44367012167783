gsap.registerPlugin(CSSRulePlugin, Draggable, EasePack, PixiPlugin, EaselPlugin, ScrollToPlugin, CustomEase, ScrollTrigger, InertiaPlugin, );
// import domtoimage from 'dom-to-image';
/* in ES 5 */
// const disableselect = (e) => {  
//     return false  
// }  
// document.onselectstart = disableselect  
// document.onmousedown = disableselect

var EMA = EMA || {};
var _window = window;
EMA.BUILD = (function() {
    return {
        init: function() {
            // Find all controller in page
            // Define template or content

            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // Convert IMG to SVG source code inline
            this.svg('img.svg-convert');

            // Load Lazy Images with animation
            this.lazyLoader();

            // toolip config
            $("[data-tooltip-content]").each(function(i){
                let controller = "TOOLTIP";
                var tooltip = $("<div class='tooltip d-flex align-items-center'><p></p></div>").appendTo($(this));
                EMA[controller].init(tooltip, {control:controller});
            });
        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: -100,
                success: function(element) {
                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 100);
                }
            });

            return bLazy;

        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        },
        parallax: function(image, speed) {
            if (Foundation.MediaQuery.atLeast('medium')) {
                image.css('transform', 'translate3D(0, ' + (EMA.BUILD.resize().scrollTop * speed) + 'px, 0)');
            }
        }
    };
})();

EMA.HOME = (function() {
    var self;
  return {
      init: function() {
        console.log("home");
        EMA.OVERLAY.show();
        $('.homepage').imagesLoaded()
            .always( function( instance ) {
                console.log('all images loaded');
                EMA.OVERLAY.hide();
            })
            .done( function( instance ) {
                console.log('all images successfully loaded');
                
            })
            .fail( function() {
                console.log('all images loaded, at least one is broken');
            })
            .progress( function( instance, image ) {
                var result = image.isLoaded ? 'loaded' : 'broken';
                console.log( 'image is ' + result + ' for ' + image.img.src );
            });
        self = this;
        
        $(".print-btn").on("click", function(){
            self.print("card-picture-container");
        })
        $(".reset-btn").on("click", function(){
            self.reset();
        })
        $(".draggable").each(function(index) {
            var angle, ratio, constrained, height;
            
            var drag = $(this);
            var handle = $("<div class='resize-handle'></div>").appendTo(drag);
            TweenLite.set(handle, { x: drag.width(), y: drag.height() });
            
            
            
            Draggable.create(drag,{
                onDrag:function(e) {
                    
                  if (this.hitTest("#card-holder", 5)) {
                      drag.appendTo('#card-holder');
                      
                    //   gsap.to($(this.currentTarget).find(".icon-close-crossmif"), .2, {autoAlpha: 1});
                    //   gsap.to($(this.currentTarget).find(".duplicate-btn"), .2, {autoAlpha: 1});
                      this.update(true, true);
                  } 
                  if (this.hitTest("#"+drag.data("parent"), 10)) {
                    drag.appendTo("#"+drag.data("parent"));
                    // gsap.to($(this.currentTarget).find(".icon-close-crossmif"), .2, {autoAlpha: 0});
                    // gsap.to($(this.currentTarget).find(".duplicate-btn"), .2, {autoAlpha: 0});
                    this.update(true, true);
                  }
               
                  
            }});
            Draggable.create(handle, {
              type:"x,y",
              bounds: { minX: 0, minY: 0, maxX: Number.MAX_VALUE, maxY: Number.MAX_VALUE },
              onRelease: function() {
                
              },
              onPress: function(e) {
                e.stopPropagation(); // cancel drag                  
                angle = Math.atan2(drag.height(), drag.width()) * 180 / Math.PI;
                ratio = drag.width() / drag.height();
              },
              
              onDrag: function(e) {
                
                
                height = this.x / ratio;
                // if (constrained && index === 0) height = this.x * Math.tan(angle * Math.PI / 180);
                // if (constrained && index === 1) h
                
                TweenLite.set(drag, { width: this.x, height: height });
                TweenLite.set(handle, { y: height });
                
              }
            });
          });
      },
      
      print: function(id){
        
        EMA.OVERLAY.show(function(){
            let el = document.getElementById(id);
            
            // $("#card-holder").css({"overflow": "hidden"});
            // $(el).css({"margin": 0});
            // $(".elements-container").hide();
            
            domtoimage.toJpeg(el, 
            {
                quality: 1,
                scale: 2,
                with: 500
            }).then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'mif.jpg';
                link.href = dataUrl;
                link.click();
                // $(el).css({"margin": "auto"});
                // $("#card-holder").css({"overflow": "visible"});
                // $(".resize-handle").show();
                // $(".elements-container").show();
                EMA.OVERLAY.hide();
            });
        });
        
      },
      reset: function() {
          $("#card-holder .card-input:not(.letter)").each(function(i){
            
            // this.update(true, true);
            var _this = this;
            var handle = $(this).find(".resize-handle");
            var _xDif = $("#"+$(this).data("parent")).offset().left;
            var _yDif = $("#"+$(this).data("parent")).offset().top;
            TweenLite.set(handle, { x: _size, y: _size });
            // var paddT = parseInt($("#"+$(_this).data("parent")).css('padding-top'), 10);
            // var paddL = parseInt($("#"+$(_this).data("parent")).css('padding-left'), 10);
            var paddT = -2;
            var paddL = -2;
            gsap.to($(this), 1, {x: $(this).data("origin-x")-$("#card-holder").offset().left+_xDif+paddL , y: $(this).data("origin-y")-$("#card-holder").offset().top+_yDif+paddT, width: _size, height:_size, ease: Elastic.easeOut, delay: i * .1, onComplete: function(){
                
                $(_this).appendTo("#"+$(_this).data("parent"));
                gsap.to($(_this),0, {x: $(_this).data("origin-x"), y: $(_this).data("origin-y")});
            }});
          })

          $(".elements .card-input").each(function(i){
            
            
            var _this = this;
            var handle = $(this).find(".resize-handle");
            
            TweenLite.set(handle, { x: _size, y: _size });
            gsap.to($(this), 1, {x: $(this).data("origin-x"), y: $(this).data("origin-y"), width: _size, height:_size, ease: Elastic.easeOut});
          })
          $("#card-holder .card-input.letter").remove();
      }
    }
})();
EMA.DRAGGABLE_CARD = (function(el) {
    var self;
    var elem;
    var _el;
    var close;
    var duplicateBtn;
    return {
        init: function(el) {
            self = this;
            elem = $(el);
            _el = el;
                
            var drag = elem;
            close = $("<span class='icon-close-crossmif'></span>").appendTo(drag);
            duplicateBtn = $("<span class='material-icons duplicate-btn'>control_point_duplicate</span>").appendTo(drag);
            
            drag.on("mousemove", self.mouseEnterHandler);
            drag.on("mouseenter", self.mouseEnterHandler);
            drag.on("mouseout", self.mouseOutHandler);
            drag.on("mousedrag", self.mouseMovePress);
            close.on("click", self.resetPosition);
            close.on("mouseenter", self.mouseEnterHandler);
            duplicateBtn.on("click", self.duplicateSelf);
            
        
        },
        mouseEnterHandler: function(e){
            
            e.preventDefault();
            e.stopPropagation();
            // console.log($(e.currentTarget).find(".icon-close-crossmif"));
            gsap.to($(e.currentTarget).find(".icon-close-crossmif"), .2, {autoAlpha: 1});
            gsap.to($(e.currentTarget).find(".duplicate-btn"), .2, {autoAlpha: 1});
            gsap.to($(e.currentTarget).find(".resize-handle"), .2, {autoAlpha: 1});
        },
        mouseOutHandler: function(e){
            e.preventDefault();
            e.stopPropagation();
            gsap.to($(e.currentTarget).find(".icon-close-crossmif"), .2, {autoAlpha: 0});
            gsap.to($(e.currentTarget).find(".duplicate-btn"), .2, {autoAlpha: 0});
            gsap.to($(e.currentTarget).find(".resize-handle"), .2, {autoAlpha: 0});
        },
        duplicateSelf: function(e){
            console.log("duplicateSelf")
        },
        mouseMovePress: function(e){
            // console.log(close);
            
        },
        resetPosition: function(e){
            
            var _this = $(e.currentTarget).parent();
            
            var handle = $(_this).find(".resize-handle");
            var _xDif = $("#"+$(_this).data("parent")).offset().left;
            var _yDif = $("#"+$(_this).data("parent")).offset().top;
            TweenLite.set(handle, { x: _size, y: _size });
            var paddT = -2;
            var paddL = -2;
            // paddL  = 0;
            console.log(paddT, paddL);
            gsap.to($(_this), 1, {x: $(_this).data("origin-x")-$("#card-holder").offset().left+_xDif+paddL , y: $(_this).data("origin-y")-$("#card-holder").offset().top+_yDif+paddT, width: _size, height:_size, ease: Elastic.easeOut, onComplete: function(){
                
                $(_this).appendTo("#"+$(_this).data("parent"));
                gsap.to($(_this),0, {x: $(_this).data("origin-x"), y: $(_this).data("origin-y")});
            }});
        }
    }
})();
EMA.DRAGGABLE_LETTER = (function(el) {
    var self;
    var elem;
    var _el;
    
    return {
        init: function(el) {
            self = this;
            elem = $(el);
            _el = el;
            // _text = elem.find(".text");
            //console.log($(el).find("text").innerHeight())

            var angle, ratio, constrained, height;
                
            var drag = elem;
            var close = $("<span class='icon-close-crossmif'></span>").appendTo(drag);
            var scaleUpBtn = $("<span class='icon-plusmif'></span>").appendTo(drag);
            var scaleDownBtn = $("<span class='icon-minusmif'></span>").appendTo(drag);
           


            
            close.on("click", self.killSelf);
            scaleUpBtn.on("click", self.scaleUpHandler);
            scaleDownBtn.on("click", self.scaleDownHandler);
            
            Draggable.create(drag,{
                onDrag:function(e) {
            }});
            
            
        
        },
        onDragHandler: function(el){
            
            // $(this.target).addClass("z-depth-1");
        },
        onDragEndHandler: function(el){
            
            // $(this.target).removeClass("z-depth-1");
        },
        killSelf: function(e){
            
            $(e.currentTarget).parent().remove()
        },
        scaleUpHandler: function(e) {
            var matrixRegex = /matrix\(\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*)\)/;
            var matches = $(e.currentTarget).parent().css('-webkit-transform').match(matrixRegex);
            var scaleCurrent = Number(matches[4]);
            
            scaleCurrent += .1;
            let elementScale = (1-(scaleCurrent-1));
            
            
            gsap.to( $(e.currentTarget).parent(), .3, {scale: scaleCurrent});
            gsap.to( $(e.currentTarget).parent().find(".icon-close-crossmif"), 0, {scale: elementScale});
            gsap.to( $(e.currentTarget).parent().find(".icon-plusmif"), 0, {scale: elementScale});
            gsap.to( $(e.currentTarget).parent().find(".icon-minusmif"), 0, {scale: elementScale});
        },
        scaleDownHandler: function(e) {
            var matrixRegex = /matrix\(\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*),\s*(-?\d*\.?\d*)\)/;
            var matches = $(e.currentTarget).parent().css('-webkit-transform').match(matrixRegex);
            var scaleCurrent = Number(matches[4]);
            
            scaleCurrent -= .1;
            
            let elementScale = 1+(1-scaleCurrent);
            
            gsap.to( $(e.currentTarget).parent(), .3, {scale: scaleCurrent});
            gsap.to( $(e.currentTarget).parent().find(".icon-close-crossmif"), 0, {scale: elementScale});
            gsap.to( $(e.currentTarget).parent().find(".icon-plusmif"), 0, {scale: elementScale});
            gsap.to( $(e.currentTarget).parent().find(".icon-minusmif"), 0, {scale: elementScale});
        }
    }
})();
var _size = 80;
EMA.ELEMENTS = (function(el) {
    var self;
    var elem;
    return {
        init: function(el) {
            self = this;
            elem = $(el);
            
            var _cols = 5;
            
            var _margin = 2;
            var _colCount = 0;
            var _rowsCount = 0;
            
            _size = elem.find(".draggable").width();
            _cols = Math.ceil(elem.width() / _size);
            

            elem.find(".draggable").each(function(i){
                
                let _x = (_colCount * (_size + _margin));
                let _y = _rowsCount * (_size + _margin);

                gsap.to($(this), 1.3, {x: _x, y: _y, ease: Back.easeOut, delay: i * .05});
                $(this).attr("data-origin-x",_x);
                $(this).attr("data-origin-y",_y);

                _colCount++;
                if(_colCount == _cols) {
                    _colCount = 0;
                    _rowsCount++;
                }
            })
        }
    }
})();

EMA.TABS = (function(el) {
    var self;
    var elem;
    return {
        init: function(el) {
            self = this;
            elem = $(el);
            $(".elements-title").on("click", self.selectTab);
            $(".elements-title:eq(0)").trigger("click");
        },
        selectTab: function(index) {
            console.log(index.currentTarget);
            index = $(index.currentTarget).data("id");
            $(".elements-title").removeClass("active");
            $(".elements-title:eq("+index+")").addClass("active");
            console.log($(".elements-title:eq("+index+")"));
            gsap.to($(".elements"), .3, {autoAlpha: 0});
            gsap.to($(".elements:eq("+index+")"), .3, {autoAlpha: 1, delay: .2});
            
        }
    }
})();

EMA.TOOLS = (function(el) {
    var self;
    var elem;
    var _maxY;
    var addTextBtn;
    var template;
    var structure;
    var templateBuild;
    var addTextInput;
    var isOpened = false;
    var dragBtn;
    var enterTextBtn;
    var selectedColor = "#77cbdc";

    return {
        init: function(el) {
            self = this;
            elem = $(el);
            addTextBtn = $(el).find(".add-text");
            console.log(addTextBtn);
            addTextInput = $(el).find(".add-text-input");
            addTextBtn.on("click", this.showText);
            enterTextBtn = $(el).find(".big-btn.shortcut");
            dragBtn = $(el).find(".drag-btn");

            _maxY = -dragBtn.height();

            template = $('.card-holder script[type="text/template"]')[0];
            structure = template.cloneNode(true).innerHTML;

            
           $(".color-btn").on("click", function(){
            gsap.to(addTextInput, .4, {backgroundColor: $(this).data("color")});
            gsap.to(addTextBtn, .4, {backgroundColor: $(this).data("color")});
            self.showText();
            selectedColor =  $(this).data("color");
           })


            // Buttons functions
            // addTextInput.on("mouseout", function(){
            //     self.hideText();
            // })
            // addTextBtn.on("mouseenter", function(){
            //     self.showText();
            // });

            
            // enterTextBtn.on("mouseenter", function(){
            //     self.showText();
            // });
            dragBtn.on("click", this.showHideTools);

            // Keypress to enter text
            addTextInput.keypress(function(event){
                if(event.keyCode == 13){
                    if(addTextInput.val()) {
                        self.addTextHandler(addTextInput.val(), selectedColor);
                    }
                }
            });
            enterTextBtn.on("click", function(){
                if(addTextInput.val()) {
                    self.addTextHandler(addTextInput.val(), selectedColor);
                }
                
            })
        },
        showHideTools: function() {
            if(!isOpened) {
                gsap.to(elem, .3, {y: _maxY, ease: Quad.easeOut});
                
                isOpened = true;
                
            } else {
                gsap.to(elem, .3, {y: 0, ease: Quad.easeOut});
                self.dragEndHandler();
                isOpened = false;
            }
        },
        hideTools: function() {
            gsap.to(elem, .3, {y: 0, ease: Quad.easeOut});
        },
        showText: function(){
            gsap.to(addTextInput, .2, {top: _maxY, ease: Quad.easeOut, onComplete: function(){
                addTextInput.focus();
            }});
            gsap.to(enterTextBtn, .2, {top: _maxY, ease: Quad.easeOut, onComplete: function(){
                // addTextInput.focus();
            }});
        },
        dragEndHandler: function(){
            isOpened = false;
            console.log(elem.css("transform"));
            self.hideText();
        },
        hideText: function(){
            gsap.to(addTextInput, .2, {top: 0, ease: Quad.easeOut});
            gsap.to(enterTextBtn, .2, {top: 0, ease: Quad.easeOut});
        },
        addTextHandler: function(inputText){
            templateBuild = EMA.BUILD.templateEngine(structure, {
                text: inputText,
                color: selectedColor
            });

            var elAdded = $(templateBuild).appendTo($(".card-holder"));
            // center type element on card
            gsap.to(elAdded, 0, {y: $(window).height() - 400, x: _maxY * 3});
            gsap.to(elAdded, 1, {x: ($(".card-holder").width()/2)-elAdded.find(".text").width()/2, y: ($(".card-holder").height()/2)-elAdded.find(".text").height()/2, ease: Elastic.easeOut});

            // ADD ON highest z-index
            var index_highest = 0; 
            $(".card-holder *").each(function() {
                
                var index_current = parseInt($(this).css("zIndex"), 10);
                if(index_current > index_highest) {
                    index_highest = index_current;
                }
            });
            
            elAdded.css("z-index", index_highest);
            

            // Apply controller
            let data = elAdded.data();
            let controller = elAdded.data("control");
            EMA[controller].init(elAdded, data);
            
            self.hideText();
        }
    }
})();


EMA.OVERLAY = (function(el) {
    var self;
    var elem;
    var spin;

    return {
        init: function(el) {
            self = this;
            elem = $(el);
            spin = $(el).find(".preloader-wrapper-container");
            console.log("OVERLAY");
        },
        show: function(callback){
            gsap.to(elem, .3, {autoAlpha: 1, onComplete: callback});
        },
        hide: function(callback){
            gsap.to(elem, .3, {autoAlpha: 0, onComplete: callback});
        }
    }
})();

EMA.TOOLTIP = (function(el) {
    var self;
    var elem;
    var _overY = -10;
    return {
        init: function(el) {
            self = this;
            elem = $(el);
            let label =  elem.parent().data("tooltip-content");
            elem.find("p").html(label);
            elem.parent().on("mouseover", this.mouseOverHandler).on("mouseout", this.mouseOutHandler)
        },
        mouseOverHandler: function(e){
            gsap.to($(e.currentTarget).find(".tooltip"), .2, {autoAlpha: 1, y: _overY});
        },
        mouseOutHandler: function(e){
            gsap.to($(e.currentTarget).find(".tooltip"), .2, {autoAlpha: 0, y: 0});
        }
    }
})();

EMA.NAV = (function() {
    var self;
    var el;
    return {
        init: function(elem) {
            this.el = elem;

            self = this;
            self.el = elem;
            this.burguer = $(elem).find(".burguer-container, .close-button");

            this.initEvents();

        },
        initEvents() {
            this.burguer.on("click", function(){
                $(self.el).toggleClass("active");
                // $(".component").toggleClass("menu-open-hide");
                if(!$(self.el).hasClass("active")) {
                    // EMA.SEARCH.closeSearch();
                }
            });
             //this.requestAnimationFrameID = requestAnimationFrame(() => this.render(this.el));
        },render: function(elem){
            var burguer = $('.burguer-container');
            var component = $('.component');

            component.each(function(){
                let item = $(this);

                if((collision(item, burguer) && inView.is(this))) {
                    if(item.data("menutheme")) {
                        let theme = item.data("menutheme");

                        if(!theme) {
                            theme = "yellow";
                        }
                        $(self.el).addClass(theme);
                        $(self.el).find(".logo").addClass(theme);
                    }
                    else {
                        $(self.el).removeClass("light");
                        $(self.el).removeClass("yellow");

                        $(self.el).find(".logo").removeClass("light");
                        $(self.el).find(".logo").removeClass("yellow");
                    }
                }
            });

            //self.requestAnimationFrameID = requestAnimationFrame(() => self.render(elem));
        }
    }
})();

EMA.LOGIN = (function(el) {
    var _this;
    var _el;
    var _type;
    var formRegister;
    var formLogin;
    var formRecover;
          
    return {
        init: function(el) {
          _this = this;
          _el = el;
          formRegister = $("#form-recover-password");
          formLogin = $("#form-login");
          formRecover = $("#form-recover-password");

          console.log("login",el);
          $("#form-selector-btn").on("click",function(e){
            e.preventDefault();
            _this.showRegister();
          })
          $("#form-login-btn").on("click",function(e){
            e.preventDefault();
            _this.showLogin();
          })
          $("#form-recover-btn").on("click",function(e){
            e.preventDefault();
            _this.showRecover();
          })
          _type = new TypeIt('.typed',{
  
            speed: 100,
            waitUntilVisible: true,
            deleteSpeed: 50}).go();
        },
        showRegister: function(){
          _type.delete(15).pause(100).type('Receber pass').go();
            
           
  
          let formRegister = $("#form-register-password");
          let formLogin = $("#form-login");
          
          gsap.to(formLogin, .3, {autoAlpha: 0, display:'none'});
          gsap.to(formRecover, .3, {autoAlpha: 0, display:'none'});
          gsap.to(formRegister, .5, {autoAlpha: 1, display:'block', delay: .3});
        }
        ,
        showLogin: function(){
          _type.delete(15).pause(100).type('Login').go();
          let formRegister = $("#form-register-password");
          let formLogin = $("#form-login");
          
          gsap.to(formRegister, .3, {autoAlpha: 0, display:'none'});
          gsap.to(formRecover, .3, {autoAlpha: 0, display:'none'});
          gsap.to(formLogin, .5, {autoAlpha: 1, display:'block', delay: .3});
        }
        ,
        showRecover: function(){
          _type.delete(15).pause(100).type('Recuperar pass').go();
          
          gsap.to(formRegister, .3, {autoAlpha: 0, display:'none'});
          gsap.to(formLogin, .3, {autoAlpha: 0, display:'none'});
          gsap.to(formRecover, .5, {autoAlpha: 1, display:'block', delay: .3});
        }
      }
  })();
  EMA.REGISTER_FORM = (function() {
    return {
        init: function(elem) {
            
            console.log("form_course")
            $(elem)
                .on('formvalid.zf.abide', function(event, form) {
                  let _form = form.serializeArray().reduce(function(obj, item) {
                    obj[item.name] = item.value;
                    return obj;
                }, {})
                  console.log("entrou",_form);
                  $.post('/frontend/engine/engine.php', {
                      'action': 'registerUserContentAdd',
                      'firstName': _form.firstName,
                      'lastName': _form.lastName,
                      'email': _form.email
                  }, function(data, status, xhr) {
                    console.log(data);
                    $(".status_form_course").html(data.label);
                      // if (data) {
                      //     console.log(data);
                      // } else {
                      //     console.error(status, xhr);
                      // }
                  })
  
                })
                .on('submit', function(event) {
                  event.preventDefault();
                });
  
        }
    };
})();
EMA.RECOVER_FORM = (function() {
    return {
        init: function(elem) {
            
            console.log("recover_form")
            $(elem)
                .on('formvalid.zf.abide', function(event, form) {
                  let _form = form.serializeArray().reduce(function(obj, item) {
                    obj[item.name] = item.value;
                    return obj;
                }, {})
                  var user = {"email": _form.email};
                  $.post('/ema/frontend/engine/engine.php', {
                      'action': 'recoverPassword',
                      'user': user
                  }, function(data, status, xhr) {
                    console.log(data);
                    $(".status_form_course").html(data.label);
                      // if (data) {
                      //     console.log(data);
                      // } else {
                      //     console.error(status, xhr);
                      // }
                  })
  
                })
                .on('submit', function(event) {
                  event.preventDefault();
                });
  
        }
    };
})();

$(function() {
  // Call when document is ready
  $(document).foundation();
  EMA.BUILD.init();
});


jQuery.fn.redraw = function() {
    return this.hide(0, function() {
        $(this).show();
    });
};

